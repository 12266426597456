import React , {Component } from 'react';
import './App.css';
import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import SideMenu from "./Components/SideMenu"
import Fields from "./Components/Fields"
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
      margin: '10px',
      flexGrow: 1,
      width: '90%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },  
    table: {
      minWidth: 650,
    },
}));

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            table: [],
            relationships: [],
            checkedRels: new Map(),
            fields: new Map(),
            loading: true,
            schema: 'pokemon'
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleSchema = this.handleSchema.bind(this);
        this.handleRels = this.handleRels.bind(this);
    }
    /** Gets table data from back end and creates
     fields state object which stores which fields are checked*/
    componentDidMount(){
        const url = "/table/" + this.state.schema
        fetch(url).then(response => {
            response.json().then(data => {
              this.setState({table: data.table, relationships: data.relationship})
              let fields = Object.assign({}, ...data.table.map((item) => ({
                    [item.title]: new Array(item.submenu.length).fill(false)
              })))
              this.setState({fields : fields})
              let rels = Object.assign({}, ...data.relationship.map((item) => ({
                  [item.name+item.nodes[0]+item.nodes[1]]: new Array(item.properties.length + 1).fill(false)
              })))
              this.setState({checkedRels : rels})
              }).then(fields => {this.setState({loading: false})})
            })
    }

    /** Changes fields when fields are checked in SideMenu */
    handleChange(e, name, index) {
        const isChecked = e.target.checked;
        this.setState(state => {
            let vals = this.state.fields[name]
            vals[index] = isChecked
            const fields2 = this.state.fields
            fields2.name = vals
            return fields2;
        });
      }

    handleRels(e, name, index) {
        let check = e.target.checked;
        this.setState(state => {
            let vals = this.state.checkedRels[name]
            vals[index] = check
            const checkedRels2 = this.state.checkedRels
            checkedRels2.name = vals
            return checkedRels2;
        });
    }

    handleSchema(e){
        let value = e.target.value
        value = value.toLowerCase()
        this.setState({schema: value})
    }

    /** Fetch new schema on change */
    componentDidUpdate(prevProps, prevState){
        if(prevState.schema !== this.state.schema){
            const url = "/table/" + this.state.schema
            fetch(url).then(response => {
                response.json().then(data => {
                let fields = Object.assign({}, ...data.table.map((item) => ({
                        [item.title]: new Array(item.submenu.length).fill(false)
                })))
                let rels = Object.assign({}, ...data.relationship.map((item) => ({
                    [item.name+item.nodes[0]+item.nodes[1]]: new Array(item.properties.length + 1).fill(false)
                })))
                this.setState({table: data.table, relationships: data.relationship, fields : fields, checkedRels : rels})
                }).then(fields => {this.setState({loading: false})})
                })
        }
    }

    render() {
        const { classes } = this.props;
        let checked = this.state.fields
        console.log(checked);
        // console.log(this.state.relationships)
        // console.log(this.state.checkedRels)
        if (checked == null)
            return null
        else {
        return (
            <div className = {classes.root}>
                <Grid container>
                    <Grid item xs={2}>
                        <SideMenu data = {this.state.table} checked = {checked} onCheck = {this.handleChange} schema = {this.state.schema} changeSchema = {this.handleSchema} relationships={this.state.relationships}
                            checkedRels = {this.state.checkedRels} handleRels = {this.handleRels} >
                        {this.state.loading ? 'Hide' : 'Show'}
                        </SideMenu>
                    </Grid>
                    <Grid item xs={10}>
                        <Fields fields = {checked} field_data = {this.state.table} schema = {this.state.schema} relationships={this.state.relationships} checkedRels = {this.state.checkedRels}/>
                    </Grid>
                </Grid>
            </div>
        );}
    }
}

App.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(useStyles)(App);