import React, {useEffect} from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import Checkbox from '@material-ui/core/Checkbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import Popper from '@material-ui/core/Popper';
import NewWindow from 'react-new-window';


export default function SideMenu(props) {
    /** Table data from schema */
    const [drawerMenuItemData, setDrawerMenuItemData] = React.useState(props.data)
    /** Stores which fields are checked in dropdown menus */
    const [checkmarks, setCheckmarks] = React.useState(props.checked)
    const [checkedRels, setCheckedRels] = React.useState(props.checkedRels)
    /** Variable for selecting dropdown */
    const [selectedIndex, setSelectedIndex] = React.useState("")
    const [showUMLDiagram, setShowUMLDiagram] = React.useState(false);
    
    const schema = props.schema[0].toUpperCase() + props.schema.slice(1)

    const [anchorEl, setanchorEl] = React.useState(null);

    const handleUMLClick = event => {
      const { currentTarget } = event;
      if (anchorEl === null) {
        setanchorEl(currentTarget);  
      } else {
        setanchorEl(null);
      }
      
    };

    /** Update props for drawer data on schema change*/
    useEffect( () => {
      setDrawerMenuItemData(props.data);
      // console.log('table data updated');
      // console.log(props.data);
    }, [props.data])

    /** Update props for checkmarks on schema change*/
    useEffect(() => {
      setCheckmarks(props.checked);
      // console.log('checkmarks updated');
      // console.log(props.checked);
    }, [props.checked])

    useEffect(() => {
      setCheckedRels(props.checkedRels)
    }, [props.checkedRels])

    const handleClick = index => {
        if (selectedIndex === index) {
          setSelectedIndex("")
        } else {
          setSelectedIndex(index)
        }
    }

    const handleSelectSchema = event => {
      props.changeSchema(event)
    }

    /** Functionality in handleChange in App.js */
    const handleChange = (event, name, index) => {
        props.onCheck(event, name, index)
    };
    if (checkmarks.size === 0 || checkedRels.size === 0) //need this because props are not set when component first loaded
      return null
    else{
    return (
        <List
            component="nav"
            display="block"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader disableSticky={true} component="div" id="nested-list-subheader">
                Schema: &nbsp;
                <Select
                  labelId='schema'
                  id='schema'
                  value={schema}
                  defaultValue='Pokemon'
                  onChange={handleSelectSchema}
                  >
                  <MenuItem value={'Pokemon'}>Pokemon</MenuItem>
                  <MenuItem value={'Presidents'}>Presidents</MenuItem>
                  <MenuItem value={'Course'}>Course</MenuItem>
                  <MenuItem value={'Hospital'}>Hospital</MenuItem>
                  <MenuItem value={'Investments'}>Investments</MenuItem>
                </Select>
                <p></p>
                <Button variant="contained" onClick={() => {setShowUMLDiagram(!showUMLDiagram)}}>
                  {showUMLDiagram ? "Hide UML Diagram" : "Show UML Diagram"}
                </Button>
                {showUMLDiagram ? 
                  <NewWindow  title={`${schema.toLowerCase()}`} onUnload={() => {setShowUMLDiagram(false)}} features={{innerWidth:1000, innerHeight:700}}>
                    <img src={`https://geyuhao33.web.illinois.edu/UML/${schema.toLowerCase()}`} width="100%"></img>
                  </NewWindow>
                  : null
                } 
                <p></p>
            </ListSubheader>}
          > 
          
            {drawerMenuItemData.map((item, index) => {
            return (
                <List key={item.title}>
                  <ListItem
                      key={item.title}
                      button
                      onClick={() => {
                      handleClick(index)
                      }}
                  >
                      <ListItemText primary={item.title} />
                      {index === selectedIndex ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={index === selectedIndex} timeout="auto" unmountOnExit>
                      <List component="div" disablePadding>
                      {item.submenu.map((sub, i) => {
                          return (
                          <ListItem key = {sub.name}>
                              <Checkbox
                              checked={checkmarks[item.title][i]}
                              onChange={(e) => handleChange(e, item.title, i)}
                              color="primary"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              id = {sub.name}
                              key = {item.title + "_" + sub.name}
                              />
                              <ListItemText primary={sub.name} />
                          </ListItem>
                          )
                      })}
                      </List>
                  </Collapse>
                </List>
            )})}
        </List>
      )
}
}

SideMenu.propTypes = {
  checked: PropTypes.object.isRequired
}