import React from 'react';  
import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
    hackerBackground: {
      backgroundColor: 'black',
      padding : '10px'
    },
    hackerText: {
        color: 'green'
    },

  }));

export default function DataWindow(props) {  
    const data = props.data;
    const classes = useStyles();
    return (  
        <Paper className={classes.hackerBackground}>
            <pre className={classes.hackerText}>{data}</pre>
        </Paper>
        
    );  
}  