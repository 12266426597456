import React, {useEffect} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import Queries from "./Queries"
import Row from "./Row"

const useStyles = makeStyles((theme) => ({
    root: {
      margin: '10px',
      flexGrow: 1,
      width: '90%',
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },  
    table: {
      minWidth: 650,
    },
}));

export default function Fields(props) {
    const classes = useStyles()
    const [display, setDisplay] = React.useState(props.fields)
    const [field_data, setFieldData] = React.useState(props.field_data)
    const [relationships, setRelationships] = React.useState(props.relationships)
    const [checkedRels, setCheckedRels] = React.useState(props.checkedRels)
    const schema = props.schema

    /**Update props on schema change */
    useEffect( () => {
        setDisplay(props.fields);
        setFieldData(props.field_data)
        // console.log('fields updated');
    }, [props.fields, props.field_data])

    useEffect(() => {
        setRelationships(props.relationships);
        setCheckedRels(props.checkedRels);
    }, [props.relationships, props.checkedRels])

    const [show, setShow] = React.useState(new Map())
    
    const [op, setOp] = React.useState(new Map())

    const [aggr, setAggr] = React.useState(new Map())

    const [crit, setCrit] = React.useState(new Map())

    const [group, setGroup] = React.useState(new Map())

    const [having, setHaving] = React.useState(new Map())

    const [havingOp, setHavingOp] = React.useState(new Map())

    //const [propertyShow, setPropertyShow] = React.useState(new Map())

    /** 
     *  For adding relationship, makea new handleShow function specifically for relationship
     *  Can use nearly exact same code as handleShow, but using a different state variable (relationShow)
     */

    const handleTbl = (name) => {
        if (op.size === 0) {
            const nextOp = {[name]: []};
            setOp(nextOp);
        } else {
            const nextOp = {...op};
            nextOp[name] = [];
            setOp(nextOp);
        }
    }

    const handleShow = (event, index) => {
        
        let name = `${event.target.name}/${index}`;
        let checked = event.target.checked;
        setShow(show.set(name, checked));
    };

    const handleOp = (event) => {
        let name = event.target.name;
        let value = event.target.value;
        if(op.size !== 0){
            const nextOp = {...op}
            nextOp[name] = value
            setOp(nextOp)
        }else{
            const nextOp = {[name]: value}
            setOp(nextOp)
        }
        setCrit(crit.set(name, ""));
    };

    const handleAggr = (event) => {
        let name = event.target.name
        let value = event.target.value
        if(aggr.size !== 0){
            const nextAggr = {...aggr}
            nextAggr[name] = value
            setAggr(nextAggr)
        }else{
            const nextAggr = {[name]: value}
            setAggr(nextAggr)
        }
    };

    const handleCrit = (event) => {
        let id = event.target.id
        let value = event.target.value
        setCrit(crit.set(id, value))
    }

    const handleHaving = (event) => {
        let id = event.target.id
        let value = event.target.value
        setHaving(having.set(id, value));
    }

    const handleHavingOp = (event) => {
        let name = event.target.name
        let value = event.target.value
        if(havingOp.size !== 0){
            const nextHavingOp = {...havingOp}
            nextHavingOp[name] = value
            setHavingOp(nextHavingOp)
        }else{
            const nextHavingOp = {[name]: value}
            setHavingOp(nextHavingOp)
        }
        setHaving(having.set(name, ""));
    };

    const handleGroup = (event) => {
        let name = event.target.name
        let checked = event.target.checked
        console.log(name + " " + checked)
        setGroup(group.set(name, checked));
    };

    if (display.size === 0)
        return null
    // console.log(field_data)
    // console.log(relationships)
    let showQueries = Object.values(display).flat().includes(true)
    return(
        <div className={classes.root}>
        <TableContainer>
            <Table className={classes.table} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="right">Table</TableCell>
                        <TableCell align="right">Field</TableCell>
                        <TableCell align="right">Show</TableCell>
                        <TableCell align="right">Operator</TableCell>
                        <TableCell align="center">Criteria</TableCell>
                        <TableCell align="right">Aggregation</TableCell>
                    </TableRow>
                </TableHead>
                {/* {row} */}
                <TableBody>
                    {field_data.map((row, index) => {
                        return <Row data={row} key = {row.title + index} display={display}
                        handleTbl={handleTbl} 
                        handleShow={handleShow} 
                        handleCrit={handleCrit} 
                        handleOp={handleOp}
                        handleAggr={handleAggr} 
                        handleGroup={handleGroup}
                        handleHaving={handleHaving}
                        handleHavingOp={handleHavingOp} />
                    })}
                    {/* {relationships.map((row, index) => {
                        return <PropertyRow data={row} key = {row.name+row.nodes[0]+row.nodes[1]} display={checkedRels}
                        handleShow={relationShow} //needs to be replaced with specific relation function
                        handleCrit={handleCrit} 
                        handleOp={handleOp}
                        handleAggr={handleAggr} />
                    })} */}
                </TableBody>
            </Table>
        </TableContainer>
        <Queries //to send relationships to backend, add prop for new relationship state map variable
            showQueries={showQueries} 
            schema = {schema} 
            ops = {op} 
            criteria = {crit} 
            show = {show} 
            aggr = {aggr} 
            group = {group} 
            field_data={field_data} 
            display = {display} 
            having={having}
            havingOp={havingOp} 
            relationships = {relationships}
            checkedRels = {checkedRels}
            />
        </div>
    )
}