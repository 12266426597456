import React from 'react';
import TableCell from '@material-ui/core/TableCell';
import Box from '@material-ui/core/Box'; 
import Checkbox from '@material-ui/core/Checkbox';
import TableRow from '@material-ui/core/TableRow';
import { Button, TableBody } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { ResponsiveNeoGraph } from "./NeoGraph";

export default function PropertyRow(props) {
    const row = props.data;
    const query = props.neoquery;
    const schema = props.schema
    const [show, setShow] = React.useState(new Map())
    const [neoUpdate, setNeoUpdate] = React.useState("")
    const [showNeo4jRelData, setShowNeo4jRelData] = React.useState(false);
    const [neoRes, setNeoRes] = React.useState("");
    const neo4juri = 'bolt://34.69.203.249'
    const neo4juser = 'neo4j'
    const neo4jpassword = 'DnR6tEfJ'

    const handleShow = (event, item) => {
        console.log(event.target, item)
        let checked = event.target.checked
        setShow(show.set(item, checked));
        console.log(show);
    };

    const setShowNeo4jRelDataTrigger = () => {  
        setShowNeo4jRelData(!showNeo4jRelData)
    }

    const handleGenerate = () => {
        let showList = []
        show.forEach((value, key) => {
            if (value === true) {
                showList.push(key)
            }
        })
        let jsonData = `{
            "query":${JSON.stringify(query)},
            "relationship": ${JSON.stringify(showList)}
        }`
        console.log(jsonData)
        fetch("/neo", {
            method: "POST",
            headers: {
                'Content-type': 'application/json'
            },
            body: jsonData
        }).then(response => {
            response.json().then(data => {
                console.log(data)
                setNeoUpdate(data.query)
            })
        })
    }

    return ( 
        <div>
        <TableContainer>
        <Table>
        <TableBody>
        {row.map((item, index) => {
        let display = "";
        if (item.bi_direction){
            display = item.nodes[0] + "<-" + item.name + "->" + item.nodes[1];
        } else {
            display = item.nodes[0] + "->" + item.name + "->" + item.nodes[1];
        }
        return(
                <TableRow key = {index}>
                    <TableCell scope="row" align="left">
                    <Checkbox
                            checked={show[index]}
                            onChange={(e) => handleShow(e, item)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            name = {item.name}
                            key = {index}
                            align = "center"
                            />
                    </TableCell>
                    <TableCell align="right">{display}</TableCell>
                </TableRow>
            )
        })}
        </TableBody>
        </Table>
        </TableContainer>
        
        <br/>
        <Grid container spacing={0}>
            <Grid container>
                Neo4j + Relation
            </Grid>
            <Grid container justify="flex-end" spacing={3}>
                <Grid item>
                    <Button onClick={handleGenerate} variant ="contained" align="right">
                        Generate
                    </Button>
                </Grid>
                <Grid item>
                    <Box mr={1} mb={2}>
                        <Button onClick={setShowNeo4jRelDataTrigger} disabled = {neoUpdate===""} variant ="contained" align="right">
                            Show
                        </Button>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
        <pre>
            <code>
                {neoUpdate.length !== 0 ? 
                    <SyntaxHighlighter language="javascript" theme={dark}>
                        {neoUpdate}
                    </SyntaxHighlighter>
                    : null}
            </code>
        </pre>
        {showNeo4jRelData ?                                 
        <ResponsiveNeoGraph
            containerId={"id2"}
            neo4jUri={neo4juri}
            neo4jUser={neo4juser}
            neo4jPassword={neo4jpassword}
            query={neoUpdate}
        />  : null}        
        </div>
    )
}