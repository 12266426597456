import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = {
    table: {
      margin: "0",
    },
    collapse: {
        margin: "0",
        width: "100%",
        flexGrow: 1,
    },
    hide: {
        display: "hidden",
        opacity: "0"
    },
    root: {
        margin: '10px',
        flexGrow: 1,
        width: '90%',
        // maxWidth: 360,
      },
};

function Row(props) {
    const { classes } = props

    const row = props.data;
    const display = props.display;

    let temp = Object.assign({}, ...row.submenu.map((item) => ({
        [row.title+ '_' + item.name]: false
    })))
    let temp2 = new Array(row.submenu.length).fill(false)
    let temp3 = Object.assign({}, ...row.submenu.map((item) => ({
        [row.title+ '_' + item.name]: ''
    })))
    const [open, setOpen] = React.useState(temp)
    const [show, setShow] = React.useState(temp2)
    const [group, setGroup] = React.useState([...temp2])
    const [op, setOp] = React.useState(temp3);
    const [crit, setCrit] = React.useState({...temp3});
    const [aggr, setAggr] = React.useState({...temp3});
    const [having, setHaving] = React.useState({...temp3});
    const [havingOp, setHavingOp] = React.useState({...temp3})

    // useEffect(()=>{
    //     console.log(row.title);
    // },[]);

    const handleShow = (event, index) => {
        let e = event
        let i = index

        let nextShow = [...show]
        nextShow[i] = !nextShow[i]
        setShow(nextShow)
        props.handleShow(e, i)
    };

    const handleOp = (event) => {
        let e = event
        let name = event.target.name
        let value = event.target.value
        if(op.size !== 0){
            const nextOp = {...op}
            nextOp[name] = value
            setOp(nextOp)
        }else{
            const nextOp = {[name]: value}
            setOp(nextOp)
        }
        let nextCrit = {...crit}
        nextCrit[String(name)] = ""
        setCrit(nextCrit)
        props.handleOp(e)
    }

    const handleCrit = (event) => {
        let value = event.target.value
        let e = event
        let id = event.target.id
        let nextCrit = {...crit}
        nextCrit[id] = value
        setCrit(nextCrit)
        props.handleCrit(e)
    }

    const handleHaving = (event) => {
        let value = event.target.value
        let e = event
        let id = event.target.id
        let nextHaving = {...having}
        nextHaving[id] = value
        setHaving(nextHaving)
        props.handleHaving(e)
    }

    const handleAggr = (event) => {
        let value = event.target.value
        let e = event
        let name = event.target.name
        let nextAggr = {...aggr}
        nextAggr[name] = value
        setAggr(nextAggr)
        props.handleAggr(e)
    }

    const handleOpen = (attr) => {
        const nextOpen = {...open}
        nextOpen[attr] = !nextOpen[attr]
        setOpen(nextOpen)
    }

    const handleGroup = (event, index) => {
        let e = event
        let i = index
        let nextGroup = [...group]
        nextGroup[i] = !nextGroup[i]
        setGroup(nextGroup)
        props.handleGroup(e)
    };

    const handleHavingOp = (event) => {
        let e = event
        let name = event.target.name
        let value = event.target.value
        if(havingOp.size !== 0){
            const nextHavingOp = {...havingOp}
            nextHavingOp[name] = value
            setHavingOp(nextHavingOp)
        }else{
            const nextHavingOp = {[name]: value}
            setHavingOp(nextHavingOp)
        }
        let nextHaving = {...having};
        nextHaving[String(name)] = "";
        setHaving(nextHaving);
        props.handleHavingOp(e)
    }

    if (display.size === 0)
        return null

    if (!display[row.title].includes(true))
        return null

    return ( row.submenu.map((item, index) => {
        let attr = row.title+ '_' + item.name
        if(display[row.title][index]){
            return (
                <React.Fragment key={attr}>
        <TableRow>
            <TableCell>
            <IconButton aria-label="expand row" size="small" onClick={() => handleOpen(attr)}>
                {open[attr] ? <ExpandLess/> : <ExpandMore />}
            </IconButton>
            </TableCell>
            <TableCell component="th" align="right" scope="row">{row.title}</TableCell>
            <TableCell align="right">{item.name}</TableCell>
            <TableCell scope="row" align="right">
                <Checkbox
                        checked={show[index]}
                        onChange={(e) => handleShow(e, index)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        name = {attr}
                        key = {attr}
                        align = "center"
                        />
            </TableCell>
            <TableCell component="th" scope="row" align="right">
                <Select
                    labelId={attr}
                    id={attr}
                    inputProps={{
                        name: attr,
                    }}
                    value={op[attr]}
                    // defaultValue=''
                    onChange={handleOp}
                    >
                    <MenuItem value = ''><em>None</em></MenuItem>
                    <MenuItem value={'=='}>&#61;&#61;</MenuItem>
                    <MenuItem value ={'>'}>&gt;</MenuItem>
                    <MenuItem value ={'<'}>&lt;</MenuItem>
                    <MenuItem value={'>='}>&gt;&#61;</MenuItem>
                    <MenuItem value={'<='}>&lt;&#61;</MenuItem>
                    <MenuItem value={'!='}>&#33;&#61;</MenuItem>
                </Select>
            </TableCell>
            <TableCell component="th" scope="row" align="right">
                <TextField
                    required
                    label='Selection Criteria'
                    value={crit[attr]}
                    id={attr}
                    onChange={handleCrit}
                    disabled={op[attr] === '' || op[attr] === undefined ? true : false}
                />
            </TableCell>
            <TableCell component="th" scope="row" align="right">
                <Select
                    labelId={attr}
                    id={attr}
                    inputProps={{
                        name: attr,
                    }}
                    value={aggr[attr]}
                    onChange={handleAggr}
                    >
                    <MenuItem value = ''><em>None</em></MenuItem>
                    <MenuItem value={'SUM'}>Sum</MenuItem>
                    <MenuItem value={'MAX'}>Max</MenuItem>
                    <MenuItem value={'MIN'}>Min</MenuItem>
                    <MenuItem value={'AVG'}>Avg</MenuItem>
                    <MenuItem value={'COUNT'}>Count</MenuItem>
                </Select>
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell className={classes.table} style={{ paddingBottom: 0, paddingTop: 0, paddingRight: 0, paddingLeft: 0}} colSpan={7}>
                <Collapse
                    className={classes.collapse} 
                    in={open[attr]}
                    name={attr}
                    timeout="auto"
                    // component={collapseComponent}
                    unmountOnExit
                >   
                    <Table className={classes.collapse} >
                        <TableBody>
                        <TableRow>
                            <TableCell><IconButton className={classes.hide} size="small"><ExpandLess className={classes.hide}/></IconButton></TableCell>
                            <TableCell component="th" align="right" scope="row">GroupBy</TableCell>
                            <TableCell align="right">
                                <Checkbox 
                                checked={group[index]}
                                onChange={(e) => handleGroup(e, index)}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                                name = {attr}
                                key = {attr}
                                align = "left"/>
                            </TableCell>
                            <TableCell align="right" scope="row">
                                Having
                            </TableCell>
                            <TableCell component="th" scope="row" align="right">
                                <Select
                                    labelId={attr}
                                    id={attr}
                                    inputProps={{
                                        name: attr,
                                    }}
                                    value={havingOp[attr]}
                                    disabled={!group[index] ? true : false}
                                    onChange={handleHavingOp}
                                    >
                                    <MenuItem value = ''><em>None</em></MenuItem>
                                    <MenuItem value={'=='}>&#61;&#61;</MenuItem>
                                    <MenuItem value ={'>'}>&gt;</MenuItem>
                                    <MenuItem value ={'<'}>&lt;</MenuItem>
                                    <MenuItem value={'>='}>&gt;&#61;</MenuItem>
                                    <MenuItem value={'<='}>&lt;&#61;</MenuItem>
                                    <MenuItem value={'!='}>&#33;&#61;</MenuItem>
                                </Select>
                            </TableCell>
                            <TableCell align="right" scope="row">
                                <TextField 
                                required
                                label='Value'
                                value={having[attr]}
                                id={attr}
                                onChange={handleHaving}
                                disabled={!group[index] || havingOp[attr] === '' || havingOp[attr] === undefined ? true : false}/>
                            </TableCell>
                            {/* <TableCell align="right" scope="row">
                            </TableCell> */}
                            <TableCell component="th" scope="row" align="right" style={{ display: "hidden",  }}>
                                <Select
                                    className = {classes.hide}
                                    defaultValue = ''
                                />
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                </Collapse>
            </TableCell>
        </TableRow>
    </React.Fragment>
            )} else return null
    })
    )
}

Row.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Row);